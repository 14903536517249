const SET_AUTHOR = (state, author) => { state.currentComment.author = author }
const SET_CONTENT = (state, content) => { state.currentComment.content = content }
const SET_LIVE_ID = (state, liveId) => { state.currentComment.liveId = liveId }
const SET_IS_CREATED = (state, boolean) => { state.isCreated = boolean }
const SET_COMMENT = (state, comment) => { state.currentComment = JSON.parse(JSON.stringify(comment)) }
const ADD_COMMENT = (state, comment) => { state.comments.unshift(comment) }
const DELETE_COMMENT = (state, commentId) => { state.comments = state.comments.filter(comment => comment.id !== commentId) }
const SET_COMMENTS = (state, comments) => { state.comments = comments }
const SET_IS_TAB_ACTIVE = (state, boolean) => { state.isTabActive = boolean }
const SET_HAS_NEW_COMMENTS = (state, boolean) => { state.hasNewComments = boolean }
const SET_COUNT_NEW_COMMENTS = (state, integer) => { state.countNewComments = integer }

export default {
  SET_AUTHOR,
  SET_CONTENT,
  SET_LIVE_ID,
  SET_IS_CREATED,
  SET_COMMENT,
  ADD_COMMENT,
  DELETE_COMMENT,
  SET_COMMENTS,
  SET_IS_TAB_ACTIVE,
  SET_HAS_NEW_COMMENTS,
  SET_COUNT_NEW_COMMENTS
}
