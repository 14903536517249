const scoreboard = state => state.scoreboard
const willSave = state => state.willSave
const scoreboardTeam1 = state => state.scoreboard.team_1
const scoreboardTeam2 = state => state.scoreboard.team_2
const scoreboardScoreTeam1 = state => state.scoreboard.score.team_1
const scoreboardScoreTeam2 = state => state.scoreboard.score.team_2
const scoreboardTime = state => state.scoreboard.time
const isScoreboardOpen = state => state.isOpen
const activeAction = state => state.activeAction
const activePlayer = state => state.activePlayer
const activeTeam = state => state.activeTeam
const activeTime = state => state.activeTime
const scoreboardActions = state => state.scoreboardActions
const activeTab = state => state.activeTab

export default {
  scoreboard,
  willSave,
  scoreboardTeam1,
  scoreboardTeam2,
  scoreboardScoreTeam1,
  scoreboardScoreTeam2,
  scoreboardTime,
  isScoreboardOpen,
  activeAction,
  activePlayer,
  activeTeam,
  activeTime,
  scoreboardActions,
  activeTab
}
