const SET_SCOREBOARD = (state, value) => { state.scoreboard = JSON.parse(value).scoreboard }
const SET_TEAM_1 = (state, value) => { state.scoreboard.team_1 = JSON.parse(JSON.stringify(value)) }
const SET_TEAM_2 = (state, value) => { state.scoreboard.team_2 = JSON.parse(JSON.stringify(value)) }
const SET_SCORE_TEAM_1 = (state, value) => { state.scoreboard.score.team_1 = parseInt(value) }
const SET_SCORE_TEAM_2 = (state, value) => { state.scoreboard.score.team_2 = parseInt(value) }
const SET_TIME = (state, value) => { state.scoreboard.time = value }
const SET_WILL_SAVE = (state, value) => { state.scoreboard.willSave = value }
const TOGGLE_IS_SCOREBOARD_OPEN = (state) => { state.isOpen = !state.isOpen }
const SET_ACTIVE_ACTION = (state, value) => { state.activeAction = value }
const SET_ACTIVE_PLAYER = (state, value) => { state.activePlayer = value }
const SET_ACTIVE_TEAM = (state, value) => { state.activeTeam = value }
const SET_ACTIVE_TIME = (state, value) => { state.activeTime = value }
const SET_SCOREBOARD_ACTIONS = (state, value) => { state.scoreboardActions = value }
const SET_ACTIVE_TAB = (state, value) => { state.activeTab = value }
const RESET = (state) => {
  const scoreboard = {
    team_1: {
      value: null,
      imageUrl: null
    },
    team_2: {
      value: null,
      imageUrl: null
    },
    score: { team_1: 0, team_2: 0 },
    time: ''
  }
  state.scoreboard = scoreboard
}
const RESET_SCOREBOARD_ACTION = (state) => {
  state.activeAction = {}
  state.activePlayer = {}
  state.activeTeam = {}
  state.activeTime = ''
}

export default {
  SET_SCOREBOARD,
  SET_TEAM_1,
  SET_TEAM_2,
  SET_SCORE_TEAM_1,
  SET_SCORE_TEAM_2,
  SET_TIME,
  SET_WILL_SAVE,
  TOGGLE_IS_SCOREBOARD_OPEN,
  SET_ACTIVE_ACTION,
  SET_ACTIVE_PLAYER,
  SET_ACTIVE_TEAM,
  SET_ACTIVE_TIME,
  SET_SCOREBOARD_ACTIONS,
  SET_ACTIVE_TAB,
  RESET,
  RESET_SCOREBOARD_ACTION
}
