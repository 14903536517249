import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  willSave: false,
  isOpen: false,
  activeTab: 'actions',
  scoreboard: {
    team_1: {
      value: null,
      imageUrl: null
    },
    team_2: {
      value: null,
      imageUrl: null
    },
    score: { team_1: 0, team_2: 0 },
    time: ''
  },
  activeAction: {},
  activeTeam: {},
  activePlayer: {},
  activeTime: '',
  scoreboardActions: []
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
