import liveblogAPI from '@/api/LiveblogAPI'
import { EditorModes } from '@/constants/editor'
import { PostsLimit } from '@/constants/pagination'
import websockets from '@/constants/websockets'
import { socket } from '@/plugins/Socket'

const fetchPosts = async ({ state, commit }, {
  liveId,
  pagination = false,
  paginationOffset = 0,
  fetchHighlighted = true,
  fetchPinned = true,
  formatImagesForLazyLoading = false
}) => {
  const params = {
    formatImagesForLazyLoading,
    ...(pagination && {
      limit: PostsLimit,
      offset: paginationOffset
    })
  }

  const posts = await liveblogAPI.fetchPosts(liveId, params)
  if (posts.length < PostsLimit) { commit('SET_CAN_LOAD_MORE_POSTS', false) }
  commit('ADD_POSTS', posts)

  if (fetchHighlighted) {
    const highlightedPosts = await liveblogAPI.fetchHighlightedPosts(liveId)
    commit('SET_HIGHLIGHTED', highlightedPosts)
  }

  if (fetchPinned) {
    const pinnedPosts = await liveblogAPI.fetchPinnedPosts(liveId)
    commit('SET_PINNED', pinnedPosts)
  }
}

const createPost = ({ commit, state, rootState, rootGetters }) => {
  const userId = rootGetters['user/user'].id

  // Survey
  if (state.currentPost.surveyId && state.currentPost.surveyCode) {
    return socket.io.emit(websockets.CREATE_ONE_POST, {
      currentPost: state.currentPost,
      userId
    })
  }

  // Comment
  if (rootGetters['editor/isCommentAnswering']) {
    if (!state.currentPost.content || state.currentPost.content === '<p></p>') {
      commit('SET_CURRENT_CONTENT', `<section class="quote-container" data-content="${state.quote.content}" data-name="${state.quote.name}" data-answer="${state.quote.answer}">
      <div class="quote__answer-container">
        ${state.quote.answer}
      </div>
      <div class="quote__comment-container">
        <div class="quote__content-container">
          ${state.quote.content}
        </div>
        <div class="quote__name-container">
          ${state.quote.name}
        </div>
      </div>
    </section>`)
    } else {
      commit('UPDATE_CURRENT_POST_CONTENT')
    }
  }

  // Action
  const action = rootState.dashboardActions && rootState.dashboardActions.action.id
  const dataPlayer = JSON.stringify(rootState.dashboardActions.dataPlayer)
  const post = {
    currentPost: state.currentPost,
    action,
    dataPlayer,
    userId
  }

  socket.io.emit(websockets.CREATE_ONE_POST, post)

  // Reset action
  commit('dashboardActions/SET_ACTION', {}, { root: true })
}

const addPost = ({ commit }, post) => {
  commit('ADD_POST', post)
  if (post.highlighted) { commit('ADD_TO_HIGHLIGHTED', post) }
}

const createSurvey = ({ commit, dispatch }, { surveyId, surveyCode, liveId }) => {
  commit('SET_CURRENT_SURVEY_ID', surveyId)
  commit('SET_CURRENT_SURVEY_CODE', surveyCode)
  dispatch('createPost', liveId)
}

const deletePost = ({ commit }, id) => {
  socket.io.emit(websockets.DELETE_ONE_POST, id)
}

const toggleHighlight = ({ commit }, post) => {
  commit('UPDATE_POST', post)
  post.highlighted ? commit('ADD_TO_HIGHLIGHTED', post) : commit('REMOVE_FROM_HIGHLIGHTED', post.id)
}

const openEditMode = ({ commit }, post) => {
  commit('editor/SET_MODE', EditorModes.POST_EDITING, { root: true })
  commit('SET_CURRENT_POST', post)
  commit('SET_CURRENT_HIGHLIGHTED', post && post.highlighted)
}

const togglePin = ({ commit, getters }, post) => {
  const currentPinnedPost = getters.pinnedPost
  if (currentPinnedPost) { commit('UPDATE_POST', { ...currentPinnedPost, pinned: false }) }
  commit('UPDATE_POST', post)
  post.pinned ? commit('SET_PINNED', [post]) : commit('RESET_PINNED')
}

const resetPost = ({ commit }) => {
  commit('SET_CURRENT_POST', { title: '', content: '<p></p>', highlighted: false })
  commit('dashboardActions/SET_ACTION', {}, { root: true })
  commit('editor/SET_MODE', null, { root: true })
}

const fetchAction = async ({ state, commit, dispatch }) => {
  await dispatch('dashboardActions/fetchAction', state.currentPost.action, { root: true })

  if (state.currentPost.dataPlayer === null) { return null }

  const data = JSON.parse(state.currentPost.dataPlayer)
  commit('dashboardActions/SET_PLAYER_DATA', data, { root: true })
}

const updatePost = ({ commit, state, rootState }) => {
  let action
  if (rootState.dashboardActions && rootState.dashboardActions.action) {
    action = rootState.dashboardActions.action.id
  } else {
    action = null
  }

  if (state.currentPost.content.includes('<section')) {
    commit('UPDATE_CURRENT_POST_CONTENT')
  }

  const dataPlayer = JSON.stringify(rootState.dashboardActions.dataPlayer)
  socket.io.emit(websockets.UPDATE_ONE_POST, { currentPost: state.currentPost, action, dataPlayer })
  commit('dashboardActions/SET_ACTION', {}, { root: true })
}

export default {
  createPost,
  addPost,
  createSurvey,
  deletePost,
  fetchPosts,
  toggleHighlight,
  togglePin,
  openEditMode,
  resetPost,
  fetchAction,
  updatePost
}
