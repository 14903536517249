import Cookies from 'js-cookie'
import urls from '@/helpers/constants/urls'
const WebSocketAsPromised = require('websocket-as-promised')

class WebsocketClient {
  constructor (host) {
    this.host = host
    this.restartDelay = 5
    this.namespace = 'Liveblog' // should match the API provider
    this.callbackPrefix = 'Liveblog'
    this.connect()
    setInterval(this.checkConnection.bind(this), 1000)
  }

  async connect () {
    this.socket = new WebSocketAsPromised(this.host, {
      createWebSocket: url => new WebSocket(url),
      extractMessageData: event => event,
      packMessage: data => JSON.stringify(data),
      unpackMessage: data => JSON.parse(data.data)
    })
    await this.socket.open()

    this.socket.sendPacked({
      type: 'connect',
      namespace: this.namespace,
      service: 'Authentication',
      callback: this.callbackPrefix + 'Connect',
      parameters: {
        authApiToken: this.getToken()
      }
    })
  }

  async checkConnection () {
    if (!this.socket.ws || this.socket.ws.readyState === WebSocket.CLOSED) {
      this.restartDelay = 5
      await this.connect()
    } else if (this.socket.ws.readyState === WebSocket.CLOSING) {
      return
    }
    if (this.restartDelay > 0) {
      this.restartDelay--
    }
  }

  async get (service, parameters, sport) {
    let callback = this.callbackPrefix + service
    if (sport) { callback += sport }
    this.socket.sendPacked({
      type: 'get',
      namespace: this.namespace,
      service,
      callback,
      parameters
    })
    return this.waitUnpackedMessage(callback)
  }

  async waitUnpackedMessage (callback) {
    return (await this.socket.waitUnpackedMessage(
      data => data.callback === callback,
      { timeout: 10000 }
    ))?.data
  }

  getToken () {
    return Cookies.get('token', { domain: urls.cookieDomain })
  }
}

export default WebsocketClient
