import { socket } from '@/plugins/Socket'
import websockets from '@/constants/websockets'

const createScoreboard = ({ state }) => {
  socket.io.emit(websockets.CREATE_ONE_SCOREBOARD, { scoreboard: state.scoreboard })
}

const saveScoreboard = ({ state, rootState }) => {
  socket.io.emit(websockets.UPDATE_ONE_SCOREBOARD, { scoreboard: state.scoreboard, scoreboardId: rootState.lives.currentLive.scoreboardId })
}

const createScoreboardAction = ({ state, commit }, { scoreboardId, actionId, dataTeam, dataPlayer, time }) => {
  socket.io.emit(websockets.CREATE_ONE_SCOREBOARD_ACTION, { scoreboardId, actionId, dataTeam, dataPlayer, time })
  commit('SET_ACTIVE_TAB', 'resume')
  commit('RESET_SCOREBOARD_ACTION')
}

const deleteScoreboardAction = ({ state }, id) => {
  socket.io.emit(websockets.DELETE_ONE_SCOREBOARD_ACTION, { id })
}

const resetScoreboardActionsState = ({ commit }) => {
  commit('RESET_SCOREBOARD_ACTION')
}

export default {
  createScoreboard,
  saveScoreboard,
  createScoreboardAction,
  deleteScoreboardAction,
  resetScoreboardActionsState
}
