<template>
  <div class="display-container">
    <DisplayHeader is-amp />

    <div class="posts-container">
      <div class="posts-container__classic">
        <div class="display-container-divider" />
        <amp-live-list
          id="amp-live-posts"
          layout="container"
          data-poll-interval="10000"
          data-max-items-per-page="50"
        >
          <div
            class="display-update"
            update
            tabindex="1"
            role="button"
            on="tap:amp-live-posts.update,amp-live-scoreboard.update"
          >
            <div>
              <svg
                width="14px"
                height="17px"
                viewBox="0 0 14 17"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="Page-1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="refresh"
                    fill="#42A5F5"
                  >
                    <path
                      id="Path"
                      d="M0,3.47906977 L0,11.4651163 C0,11.6825581 0.185294118,11.8604651 0.411764706,11.8604651 C0.638235294,11.8604651 0.823529412,11.6825581 0.823529412,11.4651163 L0.823529412,3.47906977 C0.823529412,2.98488372 1.25588235,2.56976744 1.77058824,2.56976744 L11.4676471,2.56976744 L10.4176471,3.57790698 C10.2529412,3.73604651 10.2529412,3.97325581 10.4176471,4.13139535 C10.5,4.21046512 10.6029412,4.25 10.7058824,4.25 C10.8088235,4.25 10.9117647,4.21046512 10.9941176,4.13139535 L12.7441176,2.45116279 C12.9088235,2.29302326 12.9088235,2.05581395 12.7441176,1.89767442 L10.9941176,0.21744186 C10.8294118,0.0593023256 10.5823529,0.0593023256 10.4176471,0.21744186 C10.2529412,0.375581395 10.2529412,0.612790698 10.4176471,0.770930233 L11.4676471,1.77906977 L1.77058824,1.77906977 C0.802941176,1.77906977 0,2.55 0,3.47906977 Z"
                      fill-opacity="0.49609375"
                    />
                    <path
                      id="Path"
                      d="M13.5882353,5.13953488 C13.3617647,5.13953488 13.1764706,5.31744186 13.1764706,5.53488372 L13.1764706,13.5209302 C13.1764706,14.0151163 12.7441176,14.4302326 12.2294118,14.4302326 L2.63529412,14.4302326 L3.68529412,13.422093 C3.85,13.2639535 3.85,13.0267442 3.68529412,12.8686047 C3.52058824,12.7104651 3.27352941,12.7104651 3.10882353,12.8686047 L1.35882353,14.5488372 C1.19411765,14.7069767 1.19411765,14.944186 1.35882353,15.1023256 L3.10882353,16.7825581 C3.19117647,16.8616279 3.29411765,16.9011628 3.39705882,16.9011628 C3.5,16.9011628 3.60294118,16.8616279 3.68529412,16.7825581 C3.85,16.6244186 3.85,16.3872093 3.68529412,16.2290698 L2.63529412,15.2209302 L12.2294118,15.2209302 C13.1970588,15.2209302 14,14.45 14,13.5209302 L14,5.53488372 C14,5.31744186 13.8147059,5.13953488 13.5882353,5.13953488 Z"
                    />
                  </g>
                </g>
              </svg>
              <a>
                {{ $t('app.display.amp.reload') }}
              </a>
            </div>
          </div>
          <div items>
            <Post
              v-for="(post, key) in posts"
              :key="key"
              :post="post"
              is-amp
            />
          </div>
        </amp-live-list>
      </div>
    </div>

    <Post
      class="hidden"
      :post="postGhost"
      is-amp
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Post from './Post'
import DisplayHeader from './DisplayHeader'

export default {
  name: 'DisplayAmp',
  components: {
    Post,
    DisplayHeader
  },
  data () {
    return {
      // `postGhost` is used to force CSS loading even if there is no post
      postGhost: {
        id: 'ghost',
        createdAt: new Date(),
        updatedAt: new Date(),
        deletedAt: new Date()
      }
    }
  },
  computed: {
    ...mapGetters({
      posts: 'posts/posts'
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/display/display.amp.scss';
</style>
