import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  posts: {
    default: null,
    highlighted: null,
    pinned: null,
    canLoadMore: true
  },
  currentPost: {
    title: '',
    content: '',
    highlighted: false,
    surveyId: null,
    surveyCode: null
  },
  quote: {
    content: '',
    name: '',
    answer: ''
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
