<template>
  <div
    v-if="actions && actions.length"
    class="fo-scoreboard-actions__resume-container"
  >
    <div class="fo-scoreboard__resume-actions">
      <ScoreboardFOResumeAction
        v-for="(action, key) in actions"
        :key="key"
        :team="scoreboardTeam1"
        :action="action"
      />
    </div>
    <div class="fo-scoreboard__resume-actions">
      <ScoreboardFOResumeAction
        v-for="(action, key) in actions"
        :key="key"
        :action="action"
        :team="scoreboardTeam2"
        class="reverse"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { concatenateArrays } from '@/helpers/utils/array'

import ScoreboardFOResumeAction from './ScoreboardFOResumeAction'

export default {
  name: 'ScoreboardFOResume',
  components: {
    ScoreboardFOResumeAction
  },
  props: {
    actionsToShow: {
      type: Array,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      scoreboardActions: 'scoreboard/scoreboardActions',
      scoreboardTeam1: 'scoreboard/scoreboardTeam1',
      scoreboardTeam2: 'scoreboard/scoreboardTeam2'
    }),
    actions () {
      if (!this.actionsToShow) { return this.scoreboardActions }

      // If actionsToShow is defined (array of action ids), return only specified ones
      const specifiedActions = []
      for (const act of this.actionsToShow) {
        const result = this.scoreboardActions.filter(action => action.actionId === act.id)
        if (result && result.length) { specifiedActions.push(result) }
      }
      return concatenateArrays(specifiedActions)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/scoreboard/fo-scoreboard.scss';
</style>
