import { ActionScoreboardTypes } from '@/constants/actions'

const actions = state => state.actions
const action = state => state.action
const topics = state => state.topics
const dataPlayer = state => state.dataPlayer
const picto = state => {
  return id => {
    const item = state.actions.find(action => action.id === parseInt(id))
    return item && item.picto
  }
}
const isActionsTabOpen = state => state.isActionsTabOpen
const isFavouriteActionsTabOpen = state => state.isFavouriteActionsTabOpen
const scoreboardActions = state => type => {
  const actions = []
  if (state.actions && state.actions.length) {
    for (const action of state.actions[0].actions) {
      if (type === ActionScoreboardTypes.SECONDARY) {
        // In back-side scoreboard (secondary actions), display primary actions too
        if (action.scoreboard && (action.scoreboard === ActionScoreboardTypes.SECONDARY || action.scoreboard === ActionScoreboardTypes.PRIMARY)) { actions.push(action) }
      } else {
        if (action.scoreboard && action.scoreboard === type) { actions.push(action) }
      }
    }
  }
  return actions
}

export default {
  actions,
  action,
  topics,
  dataPlayer,
  picto,
  isActionsTabOpen,
  isFavouriteActionsTabOpen,
  scoreboardActions
}
