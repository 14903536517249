const comments = state => state.comments
const hasComments = state => state.comments && state.comments.length
const commentAuthor = state => state.currentComment.author
const commentContent = state => state.currentComment.content
const currentComment = state => state.currentComment
const isCreated = state => state.isCreated
const isTabActive = state => state.isTabActive
const hasNewComments = state => state.hasNewComments
const countNewComments = state => state.countNewComments

export default {
  comments,
  hasComments,
  commentAuthor,
  commentContent,
  currentComment,
  isCreated,
  isTabActive,
  hasNewComments,
  countNewComments
}
