import { WebsocketAPI } from '../api/WebsocketAPI'

const Topics = {
  BASKETBALL: {
    id: 6, // strapi id
    canCreateScoreboard: true,
    wsSport: WebsocketAPI.SPORT_MULTISPORTS
  },
  FOOTBALL: {
    id: 3,
    canCreateScoreboard: true,
    wsSport: WebsocketAPI.SPORT_FOOTBALL
  },
  FOOTBALL_US: {
    id: 8,
    canCreateScoreboard: true,
    wsSport: null
  },
  HANDBALL: {
    id: 10,
    canCreateScoreboard: true,
    wsSport: WebsocketAPI.SPORT_MULTISPORTS
  },
  ICE_HOKEY: {
    id: 17,
    canCreateScoreboard: true,
    wsSport: null
  },
  RUGBY: {
    id: 2,
    canCreateScoreboard: true,
    wsSport: WebsocketAPI.SPORT_RUGBY
  },
  VOLLEYBALL: {
    id: 16,
    canCreateScoreboard: true,
    wsSport: WebsocketAPI.SPORT_MULTISPORTS
  }
  // and more...
  // (but atm the explicited ones are those who can have a scoreboard)
}

export { Topics }
