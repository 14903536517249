const SET_TITLE = (state, title) => { state.live.title = title }
const SET_DESCRIPTION = (state, description) => { state.live.description = description }
const SET_CUSTOMER = (state, customer) => { state.live.customer = customer }
const SET_LANGUAGE = (state, language) => { state.live.language = language }
const SET_DATE_START = (state, date) => { state.live.dateStart = date }
const SET_COMMENTS_ENABLED = (state, boolean) => { state.live.commentsEnabled = boolean }
const SET_SCOREBOARD_ENABLED = (state, boolean) => { state.live.scoreboardEnabled = boolean }
const SET_TIMELINE_ENABLED = (state, boolean) => { state.live.timelineEnabled = boolean }
const SET_LIVE = (state, currentLive) => { state.live = JSON.parse(JSON.stringify(currentLive)) }
const SET_IS_NEWLIVE = (state, value) => { state.isNewLive = value }
const SET_CURRENT_LIVE = (state, live) => { state.currentLive = live }
const SET_CURRENT_TITLE = (state, title) => { state.currentLive.title = title }
const SET_CURRENT_DESCRIPTION = (state, description) => { state.currentLive.description = description }
const SET_CURRENT_LANGUAGE = (state, language) => { state.currentLive.language = language }
const SET_CURRENT_DATE_START = (state, date) => { state.currentLive.dateStart = date }
const SET_CURRENT_COMMENTS_ENABLED = (state, boolean) => { state.currentLive.commentsEnabled = boolean }
const SET_CURRENT_SCOREBOARD_ENABLED = (state, boolean) => { state.currentLive.scoreboardEnabled = boolean }
const SET_CURRENT_SCOREBOARD_ID = (state, id) => { state.currentLive.scoreboardId = id }
const SET_CURRENT_TIMELINE_ENABLED = (state, boolean) => { state.currentLive.timelineEnabled = boolean }
const SET_LIVE_LOAD = (state, value) => {
  state.isErrorActive.title = value
  state.isErrorActive.description = value
  state.isErrorActive.language = value
  state.isErrorActive.date = value
}
const SET_LIVES = (state, value) => { state.lives = value }
const RESET_LIVES = (state) => { state.lives = [] }
const ADD_LIVES = (state, lives) => {
  state.lives = [
    ...state.lives || [],
    ...lives || []
  ]
}
const SET_CAN_LOAD_MORE_LIVES = (state, value) => { state.canLoadMore = value }
const SET_TOPICS = (state, value) => { state.live.topics = value }
const SET_SCOREBOARD_ID = (state, value) => { state.live.scoreboardId = value }
const SET_LIVE_ON_LOAD = (state, live) => { state.liveOnLoad = live }
const SET_CURRENT_HAS_CHANGES = (state, value) => { state.currentHasChanges = value }
const ADD_FAVOURITE_ACTION = (state, value) => {
  if (!Array.isArray(state.currentLive.favouriteActions)) { state.currentLive.favouriteActions = [] }
  state.currentLive.favouriteActions.push(parseInt(value))
}
const REMOVE_FAVOURITE_ACTION = (state, value) => {
  if (Array.isArray(state.currentLive.favouriteActions)) { state.currentLive.favouriteActions.splice(state.currentLive.favouriteActions.indexOf(parseInt(value)), 1) }
}

export default {
  SET_TITLE,
  SET_DESCRIPTION,
  SET_CUSTOMER,
  SET_LANGUAGE,
  SET_DATE_START,
  SET_COMMENTS_ENABLED,
  SET_SCOREBOARD_ENABLED,
  SET_TIMELINE_ENABLED,
  SET_LIVE,
  SET_IS_NEWLIVE,
  SET_CURRENT_LIVE,
  SET_CURRENT_TITLE,
  SET_CURRENT_DESCRIPTION,
  SET_CURRENT_LANGUAGE,
  SET_CURRENT_DATE_START,
  SET_CURRENT_COMMENTS_ENABLED,
  SET_CURRENT_SCOREBOARD_ENABLED,
  SET_CURRENT_TIMELINE_ENABLED,
  SET_CURRENT_SCOREBOARD_ID,
  SET_LIVE_LOAD,
  SET_LIVES,
  RESET_LIVES,
  ADD_LIVES,
  SET_CAN_LOAD_MORE_LIVES,
  SET_TOPICS,
  SET_SCOREBOARD_ID,
  SET_LIVE_ON_LOAD,
  SET_CURRENT_HAS_CHANGES,
  ADD_FAVOURITE_ACTION,
  REMOVE_FAVOURITE_ACTION
}
