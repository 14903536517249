import { textToKey } from '../helpers/utils/text'

const constants = {}

// Entities exposed through Socket.io
const entities = [
  ['post', 'posts'],
  ['live', 'lives'],
  ['comment', 'comments'],
  ['user'],
  ['scoreboard'],
  ['scoreboard action', 'scoreboard actions']
]

// Basic CRUD methods
const methods = [
  [
    'create one',
    'delete one',
    'get one',
    'update one'
  ],
  [
    'create',
    'delete',
    'get',
    'update',
    'new'
  ]
]

// Custom methods (if needed)
const customMethods = [
  'noty error',
  'noty success',
  'reload surveys',
  'toggle highlight one post',
  'toggle pin one post',
  'update customization',
  'update favourite actions'
]

// Basic methods: generation
entities.forEach(entity => {
  entity.forEach((entityFormat, key) => {
    methods[key].forEach(method => {
      const text = `${method} ${entityFormat}`
      constants[textToKey(text)] = text
    })
  })
})

// Custom methods: generation
customMethods.forEach(method => {
  constants[textToKey(method)] = method
})

export default constants
