import actionsJSON from '@/locales/actions.json'
import topicsJSON from '@/locales/topics.json'

const fetchAction = async ({ commit }, actionId) => {
  const action = actionsJSON.find((action) => Number(action.id) === Number(actionId))
  if (action) { commit('SET_ACTION', action) }
}

const fetchTopics = async ({ commit }, topicsIds = []) => {
  if (!topicsIds) { return null }

  let actions = []
  topicsIds.forEach((topicId) => {
    const topic = topicsJSON.find((topic) => Number(topic.id) === Number(topicId))
    if (topic) {
      actions.push({
        id: topic.id,
        fr: topic.fr,
        en: topic.en,
        es: topic.es,
        category: topic.category,
        actions: topic.liveblogactions
      })
    }
  })

  commit('SET_ACTIONS', actions)
}

const setAction = ({ commit }, action) => {
  commit('SET_ACTION', action)
  if (action && action.highlight) {
    commit('posts/SET_CURRENT_HIGHLIGHTED', action.highlight, { root: true })
  } else {
    commit('posts/SET_CURRENT_HIGHLIGHTED', false, { root: true })
  }
}

export default {
  fetchAction,
  fetchTopics,
  setAction
}
