/* current post */
const currentPost = state => state.currentPost
const currentPostTitle = state => state.currentPost.title
const currentPostContent = state => state.currentPost.content
const postIndex = (state, getters, rootState, rootGetters) => {
  if (!rootGetters['editor/isPostEditing']) { return null }
  return getters.posts && getters.posts.map(post => post.id).indexOf(state.currentPost.id)
}
const isTitleNull = state => state.currentPost.title === ''
const isContentNull = (state, getters, rootState, rootGetters) => {
  if (rootGetters['editor/isCommentAnswering']) { return false }
  return (state.currentPost.content === '<p></p>') || state.currentPost.content === ''
}

/* posts */
const posts = state => state.posts.default
const hasPosts = (state, getters) => getters.posts && getters.posts.length
const canLoadMore = state => state.posts.canLoadMore

/* pinned post */
const pinnedPost = state => state.posts.pinned && state.posts.pinned[0]
const hasPinned = (state, getters) => getters.pinnedPost

/* highlighted posts */
const highlightedPosts = state => state.posts.highlighted
const hasHighlighted = (state, getters) => getters.highlightedPosts && getters.highlightedPosts.length
const highlightedPostsOnly = state => {
  const result = state.posts.highlighted && state.posts.highlighted.filter(post => post.pinned === false)
  return result.length ? result : null
}
const highlightedPostsHaveAtLeastOneEmptyTitle = state => {
  const result = state.posts.highlighted && state.posts.highlighted.find(post => post.title.trim() === '')
  return !!result
}

/* quote/answer */
const quoteContent = state => state.quote.content
const quoteName = state => state.quote.name
const quoteAnswer = state => state.quote.answer

export default {
  currentPost,
  currentPostTitle,
  currentPostContent,
  postIndex,
  posts,
  hasPosts,
  canLoadMore,
  pinnedPost,
  hasPinned,
  highlightedPosts,
  hasHighlighted,
  highlightedPostsOnly,
  highlightedPostsHaveAtLeastOneEmptyTitle,
  isTitleNull,
  isContentNull,
  quoteContent,
  quoteName,
  quoteAnswer
}
