<template>
  <div :class="loaderClasses">
    <svgicon name="loader" original />
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    size: {
      type: String,
      default: 'medium',
      validator: value => ['small', 'medium', 'large'].indexOf(value) !== -1
    }
  },
  computed: {
    loaderClasses () {
      return [
        'loader',
        `loader--${this.size}`
      ]
    }
  }
}
</script>

<style lang="scss">
.loader {
  display: inline-block;
  margin: 10px 0 40px 40px;
  @media (max-width: 430px) {
    margin: 10px 0 30px 20px;
  }

  &--small {
    width: 30px;
    height: 30px;
  }

  &--medium {
    width: 45px;
    height: 45px;
  }

  &--large {
    width: 60px;
    height: 60px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
