const concatenateArrays = arr => [].concat(...arr)

const getUnique = (arr, comp) => {
  const unique = arr
    .map(e => e[comp])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter(e => arr[e]).map(e => arr[e])
  return unique
}

export { concatenateArrays, getUnique }
