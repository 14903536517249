import i18n from '@/plugins/I18n'
import { socket } from '@/plugins/Socket'
import websockets from '@/constants/websockets'
import { includeScript } from '@/helpers/utils/others'

const addNewTopic = ({ commit, state }, topicId) => {
  let topics = state.live.topics.map(a => a) // Fix: map() is used to copy topics state and to mutate = (splice/push) local one

  const index = topics.indexOf(topicId)
  if (index !== -1) topics.splice(index, 1)
  else topics.push(topicId)

  commit('SET_TOPICS', topics)
}

const addToFavouriteActions = ({ state, commit }, actionId) => {
  commit('ADD_FAVOURITE_ACTION', actionId)
  const favouriteActions = state.currentLive.favouriteActions
  socket.io.emit(websockets.UPDATE_FAVOURITE_ACTIONS, { favouriteActions })
}

const createLive = ({ commit, state, dispatch, rootState }) => {
  dispatch('posts/resetPost', false, { root: true })

  const customer = rootState.user.user.customer
  commit('SET_CUSTOMER', customer)

  socket.io.emit(websockets.CREATE_ONE_LIVE, { currentLive: state.live })
  commit('SET_IS_NEWLIVE', true)
}

const reloadEmbeds = ({ dispatch }) => {
  const hasTweets = !!document.querySelector('.twitter-tweet')
  const hasInstagrams = !!document.querySelector('.instagram-media')
  const hasSurveys = !!document.querySelector('.iDalgo-survey')

  if (hasTweets) { includeScript('twitter') }
  if (hasInstagrams) { includeScript('instagram') }
  if (hasSurveys) { includeScript('survey') }

  dispatch('medias/reloadSocials', {}, { root: true })
  dispatch('survey/reloadSurveys', {}, { root: true })
}

const removeFromFavouriteActions = ({ state, commit }, actionId) => {
  commit('REMOVE_FAVOURITE_ACTION', actionId)
  const favouriteActions = state.currentLive.favouriteActions
  socket.io.emit(websockets.UPDATE_FAVOURITE_ACTIONS, { favouriteActions })
}

const resetForm = ({ commit }) => {
  commit('SET_TITLE', '')
  commit('SET_DESCRIPTION', '')
  commit('SET_DATE_START', new Date())
  commit('SET_CURRENT_LIVE', {})
}

const resetTopics = ({ commit }) => {
  commit('SET_TOPICS', [])
}

const setCurrentLive = ({ commit, dispatch }, { live, updateLocale = false }) => {
  commit('SET_CURRENT_LIVE', live)
  if (updateLocale && live.language) { i18n.locale = live.language }
  if (live.scoreboard && live.scoreboard.data) { commit('scoreboard/SET_SCOREBOARD', live.scoreboard.data, { root: true }) }
  if (live.topics) { dispatch('dashboardActions/fetchTopics', live.topics, { root: true }) }
}

const updateLive = ({ state, commit }) => {
  commit('SET_LIVE_ON_LOAD', Object.assign({}, state.currentLive)) // Remove reactivity
  socket.io.emit(websockets.UPDATE_ONE_LIVE, { currentLive: state.currentLive })
}

export default {
  addNewTopic,
  addToFavouriteActions,
  createLive,
  reloadEmbeds,
  removeFromFavouriteActions,
  resetForm,
  resetTopics,
  setCurrentLive,
  updateLive
}
