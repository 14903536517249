<template>
  <div
    v-if="!isAmp || (isAmp && !post.surveyCode)"
    :id="postId"
    :class="postClasses"
    :data-id="post.id"
    :data-sort-time="new Date(post.createdAt).getTime()"
    :data-update-time="new Date(post.updatedAt).getTime()"
    :data-tombstone="isDeleted"
  >
    <div class="dp-aside" :class="{ 'have-action': post.action }">
      <div v-if="post.action" class="dp-aside__icon">
        <span
          class="dp-aside__icon__image"
          :class="{
            'highlighted': post.highlighted,
            'pinned': post.pinned
          }"
        >
          <!-- Player image (img or amp-img) -->
          <div v-if="isAmp && !image.error" class="is-amp">
            <amp-img class="contain" layout="fill" :src="image.url" />
          </div>
          <img v-if="!isAmp && !image.error" :src="image.url">
          <i v-if="image.error" class="ida-icon-user" />
        </span>

        <span class="dp-aside__icon__action">
          <!-- Action icon -->
          <div v-if="picto" class="picto" v-html="picto" />
          <i v-else class="ida-icon-liveblog" />
        </span>
      </div>

      <div class="dp-aside__time" :title="formatDate(post.createdAt).title">
        {{ formatDate(post.createdAt).date }}
      </div>

      <!-- Post icons (pinned/highlighted) -->
      <div v-if="!isAmp" class="dp-icons">
        <div v-if="post.pinned" class="dp-icon dp-icon__pinned" :title="$t('app.preview.pinned.tag')">
          <i class="ida-icon-pin-filled" />
        </div>
        <div v-if="post.highlighted" class="dp-icon dp-icon__highlighted" :title="$t('app.preview.highlighted.tag')">
          <i class="ida-icon-star-rounded-filled" />
        </div>
      </div>

      <!-- Action icon (mobile only) -->
      <div v-if="post.action && picto" class="picto--mobile" v-html="picto" />

      <!-- Player (mobile only) -->
      <div v-if="player" class="player--mobile" v-html="player" />
    </div>

    <div class="dp-main">
      <!-- Survey post (do not display it in AMP) -->
      <div v-if="post.surveyCode" class="iDalgo-survey" :data-key="post.surveyCode" />

      <!-- ...or classical post -->
      <div v-else>
        <h2 v-if="post.title" class="dp-main__title">
          {{ post.title }}
        </h2>
        <div v-if="isAmp" class="dp-main__content" v-html="post.ampContent" />
        <div v-else class="dp-main__content" v-html="post.content" />
      </div>
    </div>

    <div class="display-container-divider--bottom" />
  </div>
</template>

<script>
import { formatDate } from '@/helpers/utils/date'
import { formatId } from '@/helpers/utils/others'

export default {
  name: 'Post',
  props: {
    post: {
      type: Object,
      default: null
    },
    isAmp: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: String,
      default: null
    }
  },
  computed: {
    image () {
      if (this.post.dataPlayer) {
        const { imageUrl } = JSON.parse(this.post.dataPlayer)
        if (!imageUrl) { return { error: true, url: '../../../assets/svg/user.svg' } }
        return { error: false, url: imageUrl }
      }
      return {}
    },
    isDeleted () {
      return !!this.post.deletedAt
    },
    picto () {
      if (this.post.action) {
        const topics = this.$store.getters['dashboardActions/actions']
        if (!topics) { return null }

        let item = null
        topics.find(topic => {
          item = topic.actions.find(action => action.id === parseInt(this.post.action))
          return item
        })
        return item && item.picto
      }
      return null
    },
    player () {
      return this.post.dataPlayer && JSON.parse(this.post.dataPlayer).value
    },
    postClasses () {
      return [
        'dp',
        { 'dp--h': this.post.highlighted && this.activeTab === 'live' }
      ]
    },
    postId () {
      return formatId(this.post.id)
    }
  },
  methods: {
    formatDate
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/variables.scss';
@import '@/assets/stylesheets/components/display/display-post.scss';
@import '@/assets/stylesheets/components/embed-video.scss';
</style>
