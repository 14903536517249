<template>
  <Loader v-if="isLoading" size="large" />
  <div v-else class="display-header__container">
    <h1 class="display-header__title">
      {{ currentLive.title }}
    </h1>
    <div class="display-header__description" v-html="currentLive.description" />
    <ScoreboardFO v-if="scoreboardEnabled" :is-amp="isAmp" />
    <div class="divider" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/common/Loader'
import ScoreboardFO from '../Scoreboard/ScoreboardFO/ScoreboardFO'

export default {
  name: 'DisplayHeader',
  components: { Loader, ScoreboardFO },
  props: {
    isAmp: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      currentLive: 'lives/currentLive',
      scoreboardEnabled: 'lives/currentScoreboardEnabled'
    }),
    isLoading () {
      return !this.currentLive
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/display/display-header.scss';
</style>
