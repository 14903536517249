import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  actions: null,
  action: {},
  topics: null,
  dataPlayer: {
    value: '',
    imageUrl: ''
  },
  isFavouriteActionsTabOpen: true,
  isActionsTabOpen: true
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
