import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  comments: [],
  currentComment: {
    author: '',
    content: '',
    liveId: ''
  },
  isAuthorCustomized: false,
  isCreated: false,
  isTabActive: false,
  hasNewComments: false,
  countNewComments: 0
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
