import Vue from 'vue'
import SocketIO from 'socket.io-client'
import urls from '@/helpers/constants/urls'

class Socket {
  constructor (namespace = '/liveblog') {
    if (!Socket.instance) {
      this.connect(namespace)
      Socket.instance = this
    }
  }

  install (Vue) {
    Vue.prototype.$socket = () => this.io
    Vue.prototype.$socketIo = this
  }

  async connect (namespace) {
    this.io = await SocketIO(urls.ws + namespace, {
      transports: ['websocket'],
      secure: true,
      rejectUnauthorized: false,

      /* these heartbeat values should be the same as the server's ones */
      pingTimeout: 30000,
      pingInterval: 10000,

      /* by default in Socket.io v2 (not in v3 anymore), the `permessage-deflate` extension is enabled
       * and adds a significant overhead in terms of performance and memory consumption.
       * the official documentation recommands to enable it only if necessary so let's disable it. */
      perMessageDeflate: false
    })
    this.io.on('reconnect_attempt', () => {
      this.io.io.opts.transports = ['websocket', 'polling']
    })
  }
}
const socket = new Socket()

Vue.use(socket)

export { socket }
