import { format } from 'date-fns'
import Cookies from 'js-cookie'

const formatDate = (date, type = null) => {
  const { hour, month, year, fullDate } = getLocaleFormats()
  const diff = Math.abs(new Date() - new Date(date)) / 36e5

  if (!type && diff < 24) type = 'hour'
  else if (!type && diff >= 24) type = 'month'

  const formattedDate = {
    hour: { date: format(new Date(date), hour), title: format(new Date(date), fullDate) },
    month: { date: format(new Date(date), month), title: format(new Date(date), fullDate) },
    year: { date: format(new Date(date), year), title: format(new Date(date), fullDate) }
  }[type || 'year']

  return formattedDate
}

const getLocaleFormats = (locale = null) => {
  locale = locale || Cookies.get('locale')

  const formats = {
    fr: { hour: 'HH:mm', month: 'dd/MM', year: 'dd/MM/yy', fullDate: 'dd/MM/yyyy HH:mm' },
    en: { hour: 'hh:mm aa', month: 'MM-dd', year: 'yyyy-MM-dd', fullDate: 'yyyy-MM-dd hh:mm aa' },
    es: { hour: 'HH:mm', month: 'dd/MM', year: 'dd/MM/yy', fullDate: 'dd/MM/yyyy HH:mm' }
  }[locale || 'fr']

  return formats
}

export { formatDate }
