<template>
  <div
    v-if="JSON.parse(action.dataTeam).value === team.value"
    class="fo-scoreboard-action__resume--action"
  >
    <div class="fo-scoreboard__resume_action-player">
      <div class="fo-scoreboard__resume_action-player-name">
        <span :title="playerName">{{ playerName }}</span>
      </div>
      <div class="fo-scoreboard__resume_action-time">
        {{ action.time }}
      </div>
    </div>
    <div class="picto-container">
      <div
        class="picto"
        v-html="actionsScoreboard(action.actionId)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ScoreboardFOResumeAction',
  props: {
    action: {
      type: Object,
      default: null
    },
    team: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      actions: 'dashboardActions/actions'
    }),
    actionsScoreboard () {
      return data => {
        if (this.actions && this.actions.length) {
          for (const action of this.actions[0].actions) {
            if (action.id === data) {
              return action.picto
            }
          }
        }
      }
    },
    playerName () {
      return this.action.dataPlayer && JSON.parse(this.action.dataPlayer).value
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/scoreboard/bo-scoreboard-actions.scss';
</style>
