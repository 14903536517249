<template>
  <div class="fo-scoreboard-container">
    <div class="fo-scoreboard">
      <div class="fo-scoreboard--team fo-scoreboard__team_1-container">
        <img
          v-if="!isAmp && scoreboard.team_1.imageUrl"
          :src="scoreboard.team_1.imageUrl"
        >
        <amp-img
          v-if="isAmp && scoreboard.team_1.imageUrl"
          width="45"
          height="45"
          :src="scoreboard.team_1.imageUrl"
        />
        <span>{{ scoreboard.team_1.value }}</span>
      </div>

      <div
        v-if="!isAmp"
        class="score-container"
      >
        <div class="score-container--score">
          <div class="input-group team_1">
            <span class="score">{{ scoreboardScoreTeam1 }}</span>
          </div>
          <span class="score-separator">-</span>
          <div class="input-group team_2">
            <span class="score">{{ scoreboardScoreTeam2 }}</span>
          </div>
        </div>
        <div class="score-time">
          <span class="time">{{ scoreboardTime }}</span>
        </div>
      </div>

      <div
        v-else
        class="score-container"
      >
        <amp-live-list
          id="amp-live-scoreboard"
          layout="container"
          data-poll-interval="10000"
          data-max-items-per-page="5"
          class="scoreboard-amp-list"
        >
          <div
            update
            tabindex="1"
          />
          <div
            items
            class="score-container--score isAmp"
          >
            <div
              id="teams"
              :data-sort-time="new Date().getTime()"
              :data-update-time="new Date().getTime()"
            >
              <div
                id="team_1"
                :data-sort-time="new Date().getTime()"
                :data-update-time="new Date().getTime()"
                class="input-group team_1"
              >
                <span class="score">{{ scoreboardScoreTeam1 }}</span>
              </div>
              <span
                id="separator"
                :data-sort-time="new Date().getTime()"
                class="score-separator"
              >-</span>
              <div
                id="team_2"
                :data-sort-time="new Date().getTime()"
                :data-update-time="new Date().getTime()"
                class="input-group team_2"
              >
                <span class="score">{{ scoreboardScoreTeam2 }}</span>
              </div>
            </div>
            <div
              id="status"
              class="score-time"
              :data-sort-time="new Date().getTime()"
              :data-update-time="new Date().getTime()"
            >
              <span class="time">{{ scoreboardTime }}</span>
            </div>
          </div>
        </amp-live-list>
      </div>

      <div class="fo-scoreboard--team fo-scoreboard__team_2-container">
        <span>{{ scoreboard.team_2.value }}</span>
        <amp-img
          v-if="isAmp && scoreboard.team_2.imageUrl"
          width="45"
          height="45"
          :src="scoreboard.team_2.imageUrl"
        />
        <img
          v-if="!isAmp && scoreboard.team_2.imageUrl"
          :src="scoreboard.team_2.imageUrl"
        >
      </div>
    </div>
    <ScoreboardFOResume
      v-if="!isOpen"
      :actions-to-show="scoreboardActionsPrimary"
    />
    <ScoreboardFOResume v-if="isOpen" />
    <div
      v-if="!isAmp && hasDetails"
      class="fo-scoreboard--details"
      @click="handleClick"
    >
      {{ showMoreText }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ActionScoreboardTypes } from '@/constants/actions'

import ScoreboardFOResume from './ScoreboardFOResume'

export default {
  name: 'ScoreboardFO',
  components: {
    ScoreboardFOResume
  },
  props: {
    isAmp: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters({
      scoreboard: 'scoreboard/scoreboard',
      scoreboardActions: 'scoreboard/scoreboardActions',
      scoreboardScoreTeam1: 'scoreboard/scoreboardScoreTeam1',
      scoreboardScoreTeam2: 'scoreboard/scoreboardScoreTeam2',
      scoreboardTime: 'scoreboard/scoreboardTime'
    }),
    hasDetails () {
      return this.scoreboardActions && this.scoreboardActions.length && this.scoreboardActions.find(action => !this.scoreboardActionsPrimary.includes(action.actionId))
    },
    scoreboardActionsPrimary () {
      return this.$store.getters['dashboardActions/scoreboardActions'](ActionScoreboardTypes.PRIMARY)
    },
    showMoreText () {
      if (this.isOpen) { return this.$t('app.scoreboard.fo.show.less') } else return this.$t('app.scoreboard.fo.show.more')
    }
  },
  methods: {
    handleClick () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/scoreboard/fo-scoreboard.scss';
</style>
