import axios from 'axios'
import Cookies from 'js-cookie'
import urls from '@/helpers/constants/urls'

class Http {
  constructor (config) {
    if (!config.disableAuthorization) { config.headers = Http.headers }
    this.http = axios.create(config)
  }

  async get (url, params) {
    const response = await this.http.get(url, { params })
    return response.data
  }

  async post (url, payload) {
    const response = await this.http.post(url, payload)
    return response.data
  }

  async patch (url, payload) {
    const response = await this.http.patch(url, payload)
    return response.data
  }

  static headers = {
    Authorization: `Bearer ${Cookies.get('token', { domain: urls.cookieDomain })}`
  }
}

export default Http
