import Vue from 'vue'
import VueI18n from 'vue-i18n'
import fr from '@/locales/fr.json'
import en from '@/locales/en.json'
import es from '@/locales/es.json'
import Cookies from 'js-cookie'
import urls from '@/helpers/constants/urls'

Vue.use(VueI18n)

const locale = Cookies.get('locale', { domain: urls.cookieDomain }) || 'en'
const messages = {
  fr,
  en,
  es
}
const i18n = new VueI18n({
  locale,
  messages,
  silentFallbackWarn: true
})

export default i18n
