import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  live: {
    title: '',
    description: '',
    language: 'fr',
    dateStart: new Date(),
    customer: 0,
    commentsEnabled: true,
    scoreboardEnabled: false,
    scoreboardId: null,
    timelineEnabled: false,
    topics: []
  },
  currentLive: {},
  liveOnLoad: {},
  currentHasChanges: false,
  isErrorActive: {
    title: true,
    description: true,
    language: true,
    date: true
  },
  isNewLive: false,
  lives: [],
  canLoadMore: true
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
