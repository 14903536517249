const urls = {
  cookieDomain: process.env.VUE_APP_COOKIE_DOMAIN ? process.env.VUE_APP_COOKIE_DOMAIN : window.VUE_APP_COOKIE_DOMAIN,
  foAccount: process.env.VUE_APP_FO_ACCOUNT_URL ? process.env.VUE_APP_FO_ACCOUNT_URL : window.VUE_APP_FO_ACCOUNT_URL,
  api: process.env.VUE_APP_API_URL ? `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}` : window.VUE_APP_API_URL,
  ws: process.env.VUE_APP_WS_URL ? process.env.VUE_APP_WS_URL : window.VUE_APP_WS_URL,
  wsApi: process.env.VUE_APP_WS_API_URL ? process.env.VUE_APP_WS_API_URL : window.VUE_APP_WS_API_URL,
  cdn: process.env.VUE_APP_CDN_URL ? process.env.VUE_APP_CDN_URL : window.VUE_APP_CDN_URL,
  boSurvey: process.env.VUE_APP_BO_SURVEY_URL ? process.env.VUE_APP_BO_SURVEY_URL : window.VUE_APP_BO_SURVEY_URL,
  foSurvey: process.env.VUE_APP_FO_SURVEY_URL ? process.env.VUE_APP_FO_SURVEY_URL : window.VUE_APP_FO_SURVEY_URL
}

export default urls
