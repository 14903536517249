import { replaceSvgTitle } from '@/helpers/utils/others'
import i18n from '@/plugins/I18n'

const SET_ACTION = (state, action) => { state.action = JSON.parse(JSON.stringify(action)) }
const SET_ACTIONS = (state, data) => {
  for (const item of data) {
    for (const act of item.actions) {
      act.picto = replaceSvgTitle(act.picto, act.name)
    }
    item.actions = item.actions.sort((a, b) => a[i18n.locale] > b[i18n.locale] ? 1 : -1)
  }
  state.actions = data
}
const SET_TOPICS = (state, topics) => { state.topics = topics }
const SET_PLAYER_DATA = (state, value) => { state.dataPlayer = JSON.parse(JSON.stringify(value)) }
const SET_NULL_IMG = (state) => { state.dataPlayer.imageUrl = null }
const TOGGLE_ACTIONS_TAB = (state) => { state.isActionsTabOpen = !state.isActionsTabOpen }
const TOGGLE_FAVOURITE_ACTIONS_TAB = (state) => { state.isFavouriteActionsTabOpen = !state.isFavouriteActionsTabOpen }

export default {
  SET_ACTION,
  SET_ACTIONS,
  SET_TOPICS,
  SET_PLAYER_DATA,
  SET_NULL_IMG,
  TOGGLE_ACTIONS_TAB,
  TOGGLE_FAVOURITE_ACTIONS_TAB
}
