import Http from '@/helpers/Http'
import urls from '@/helpers/constants/urls'

class LiveblogAPI extends Http {
  constructor () {
    const axiosConfig = { baseURL: urls.api }
    super(axiosConfig)
  }

  fetchLive (liveId) {
    return this.get(`/live/${liveId}`)
  }

  fetchPosts (liveId, params = {}) {
    return this.get(`/live/${liveId}/posts`, Object.assign(params))
  }

  fetchHighlightedPosts (liveId, params = {}) {
    return this.get(`/live/${liveId}/posts`, Object.assign({ ...params, type: 'highlighted' }))
  }

  fetchPinnedPosts (liveId, params = {}) {
    return this.get(`/live/${liveId}/posts`, Object.assign({ ...params, type: 'pinned' }))
  }

  fetchUser () {
    return this.get('/user/me')
  }
}

export default new LiveblogAPI()
