import { socket } from '@/plugins/Socket'
import { getRandomizedUsername } from '@/helpers/utils/others'
import { EditorModes } from '@/constants/editor'
import websockets from '@/constants/websockets'

const addNewComment = ({ commit, state }, comment) => {
  commit('ADD_COMMENT', comment)
  if (state.isTabActive) {
    commit('SET_HAS_NEW_COMMENTS', true)
    commit('SET_COUNT_NEW_COMMENTS', state.countNewComments + 1)
  }
}

const createComment = async ({ commit, state }, liveId) => {
  commit('SET_LIVE_ID', liveId)
  if (state.currentComment && state.currentComment.author === '') { commit('SET_AUTHOR', getRandomizedUsername()) }
  if (state.currentComment && state.currentComment.content) { commit('SET_CONTENT', state.currentComment.content.replace(/"/g, '')) }
  if (state.currentComment && state.currentComment.answer) { commit('SET_ANSWER', state.currentComment.answer.replace(/"/g, '')) }
  await socket.io.emit(websockets.CREATE_ONE_COMMENT, { currentComment: state.currentComment })
  await socket.io.on(websockets.CREATE_ONE_COMMENT, () => { commit('SET_IS_CREATED', true) })
}

const deleteComment = async ({ commit }, id) => {
  await socket.io.emit(websockets.DELETE_ONE_COMMENT, id)
}

const resetComment = ({ commit }) => {
  commit('SET_CONTENT', '')
}

const resetNewComments = ({ commit }) => {
  commit('SET_COUNT_NEW_COMMENTS', 0)
  commit('SET_HAS_NEW_COMMENTS', false)
}

const switchToAnswerComment = ({ commit }, comment) => {
  commit('editor/SET_MODE', EditorModes.COMMENT_ANSWERING, { root: true })
  commit('SET_COMMENT', comment)
}

export default {
  addNewComment,
  createComment,
  deleteComment,
  resetComment,
  resetNewComments,
  switchToAnswerComment
}
