import { parseHooks } from '@/helpers/utils/others'

/* current post */
const SET_CURRENT_TITLE = (state, title) => { state.currentPost.title = title }
const SET_CURRENT_CONTENT = (state, content) => { state.currentPost.content = content }
const SET_CURRENT_HIGHLIGHTED = (state, highlighted) => { state.currentPost.highlighted = highlighted }
const SET_CURRENT_SURVEY_ID = (state, surveyId) => { state.currentPost.surveyId = surveyId }
const SET_CURRENT_SURVEY_CODE = (state, surveyCode) => { state.currentPost.surveyCode = surveyCode }
const SET_CURRENT_POST = (state, post) => { state.currentPost = JSON.parse(JSON.stringify(post)) }
const UPDATE_CURRENT_POST_CONTENT = (state) => {
  state.currentPost.content = state.currentPost.content.replace(/<section[\s\S]*?>[\s\S]*?<\/section>/g,
    `<section class="quote-container" data-content="${state.quote.content}" data-name="${state.quote.name}" data-answer="${state.quote.answer}">
      <div class="quote__answer-container">
        ${state.quote.answer}
      </div>
      <div class="quote__comment-container">
        <div class="quote__content-container">
          ${state.quote.content}
        </div>
        <div class="quote__name-container">
          ${state.quote.name}
        </div>
      </div>
    </section>`
  )
}

/* posts (default, highlighted, pinned) */
const ADD_POST = (state, post) => {
  post.content = parseHooks(post.content)
  state.posts.default.unshift(post)
}
const ADD_POSTS = (state, posts) => {
  posts.map(function (post) { post.content = parseHooks(post.content); return post })
  state.posts.default = [
    ...state.posts.default || [],
    ...posts || []
  ]
}
const DELETE_POST = (state, postId) => {
  state.posts.default = state.posts.default.filter(post => post.id !== postId)
  state.posts.highlighted = state.posts.highlighted.filter(post => post.id !== postId)
  state.posts.pinned = state.posts.pinned && state.posts.pinned.id === postId ? null : state.posts.pinned
}
const UPDATE_POST = (state, updatedPost) => {
  state.posts.default = state.posts.default.map(currentPost => {
    if (currentPost.id !== updatedPost.id) { return currentPost }

    if (updatedPost.highlighted) {
      state.posts.highlighted = state.posts.highlighted.map(currentPost => currentPost.id === updatedPost.id ? updatedPost : currentPost)
    }

    if (updatedPost.pinned) {
      state.posts.pinned = updatedPost
    }

    updatedPost.content = parseHooks(updatedPost.content)
    return updatedPost
  })
}
const SET_HIGHLIGHTED = (state, highlightedPosts) => {
  highlightedPosts.map(function (post) { post.content = parseHooks(post.content); return post })
  state.posts.highlighted = highlightedPosts
}
const SET_PINNED = (state, pinnedPosts) => {
  pinnedPosts.map(function (post) { post.content = parseHooks(post.content); return post })
  state.posts.pinned = pinnedPosts
}
const ADD_TO_HIGHLIGHTED = (state, post) => { state.posts.highlighted.unshift(post) }
const REMOVE_FROM_HIGHLIGHTED = (state, postId) => { state.posts.highlighted = state.posts.highlighted.filter(post => post.id !== postId) }
const SET_CAN_LOAD_MORE_POSTS = (state, value) => { state.posts.canLoadMore = value }
const RESET_PINNED = (state) => { state.posts.pinned = null }
const RESET_POSTS = (state) => { state.posts.default = null }

/* quote/answer */
const SET_QUOTE_NAME = (state, name) => { state.quote.name = name }
const SET_QUOTE_CONTENT = (state, content) => { state.quote.content = content }
const SET_QUOTE_ANSWER = (state, value) => { state.quote.answer = value.replace(/"/g, '') }

export default {
  SET_CURRENT_TITLE,
  SET_CURRENT_CONTENT,
  SET_CURRENT_HIGHLIGHTED,
  SET_CURRENT_SURVEY_ID,
  SET_CURRENT_SURVEY_CODE,
  SET_CURRENT_POST,
  UPDATE_CURRENT_POST_CONTENT,
  ADD_POST,
  ADD_POSTS,
  DELETE_POST,
  UPDATE_POST,
  SET_HIGHLIGHTED,
  SET_PINNED,
  ADD_TO_HIGHLIGHTED,
  REMOVE_FROM_HIGHLIGHTED,
  SET_CAN_LOAD_MORE_POSTS,
  RESET_PINNED,
  RESET_POSTS,
  SET_QUOTE_CONTENT,
  SET_QUOTE_NAME,
  SET_QUOTE_ANSWER
}
