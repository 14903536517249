import { getUnique } from '@/helpers/utils/array'
import { Topics } from '@/constants/topics'

const customerLives = state => state.lives
const canLoadMore = state => state.canLoadMore
const customerHasLives = (state, getters) => getters.customerLives && getters.customerLives.length

const live = state => state.live
const title = state => state.live.title
const description = state => state.live.description
const language = state => state.live.language
const dateStart = state => state.live.dateStart
const commentsEnabled = state => state.live.commentsEnabled
const topics = state => state.live.topics
const isSingleTopic = (state, getters) => Array.isArray(getters.topics) && getters.topics.length === 1
const formattedTopic = (state, getters) => Object.values(Topics).find(topic => topic.id === getters.topics[0])
const scoreboardEnabled = state => state.live.scoreboardEnabled
const scoreboardId = state => state.live.scoreboardId
const timelineEnabled = state => state.live.timelineEnabled

const isNewLive = state => state.isNewLive
const isTitleError = state => state.live.title === '' && !state.isErrorActive.title
const isLanguageError = state => state.live.language === '' && !state.isErrorActive.language
const isDateError = state => (state.live.date === '' && !state.isErrorActive.date) || state.live.date === null

const currentLive = state => state.currentLive
const currentTitle = state => state.currentLive && state.currentLive.title
const currentDescription = state => state.currentLive && state.currentLive.description
const currentLanguage = state => state.currentLive && state.currentLive.language
const currentDateStart = state => state.currentLive && state.currentLive.dateStart
const currentUserName = state => state.currentLive.userFirstName === null && state.currentLive.userLastName === null
  ? null
  : state.currentLive.userLastName === state.currentLive.userFirstName
    ? `${state.currentLive.userFirstName}`
    : `${state.currentLive.userFirstName} ${state.currentLive.userLastName}`
const currentCommentsEnabled = state => state.currentLive && state.currentLive.commentsEnabled
const currentScoreboardEnabled = state => state.currentLive.scoreboardEnabled
const currentScoreboardId = state => state.currentLive.scoreboardId
const currentTimelineEnabled = state => state.currentLive.timelineEnabled
const currentHasChanges = state => state.currentHasChanges
const currentFavouritesActions = (state, getters, rootState, rootGetters) => {
  if (!rootGetters['dashboardActions/actions']) { return null }

  let array = []
  for (const acts of rootGetters['dashboardActions/actions']) {
    for (const act of acts.actions) {
      if (state.currentLive.favouriteActions && state.currentLive.favouriteActions.includes(act.id)) {
        array.push(act)
      }
    }
  }

  if (!array.length) { return null }

  array = getUnique(array, 'id') // Remove duplicatas
  array.sort((a, b) => state.currentLive.favouriteActions.indexOf(a.id) - state.currentLive.favouriteActions.indexOf(b.id))
  return array
}
const currentCustomCssVariables = state => {
  if (state.currentLive.customization && state.currentLive.customization.colors) {
    let cssVariables = {}
    for (const [key, value] of Object.entries(state.currentLive.customization.colors)) { cssVariables[`--color-${key}`] = value }
    return cssVariables
  }
  return null
}
const currentCustomBackgroundColor = state => state.currentLive.customization && state.currentLive.customization.colors && state.currentLive.customization.colors.background
const liveOnLoad = state => state.liveOnLoad

export default {
  customerLives,
  canLoadMore,
  customerHasLives,
  live,
  title,
  description,
  language,
  dateStart,
  commentsEnabled,
  topics,
  isSingleTopic,
  formattedTopic,
  scoreboardEnabled,
  scoreboardId,
  timelineEnabled,
  isNewLive,
  isTitleError,
  isLanguageError,
  isDateError,
  currentLive,
  currentTitle,
  currentDescription,
  currentLanguage,
  currentDateStart,
  currentUserName,
  currentCommentsEnabled,
  currentScoreboardEnabled,
  currentScoreboardId,
  currentTimelineEnabled,
  currentHasChanges,
  currentFavouritesActions,
  currentCustomCssVariables,
  currentCustomBackgroundColor,
  liveOnLoad
}
